<template>
  <div id="simple-calendar-app">
    <v-card class="p-0">
      <!-- HEADER TOOLBAR -->
      <v-toolbar class="page-primary-card-header">
        <v-list-item dark>
          <v-list-item-avatar color="primary lighten-2">
            <v-icon>{{ card_header_props.header.icon }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="headline">{{
              card_header_props.header.headLine
            }}</v-list-item-title>
            <v-list-item-subtitle>{{
              card_header_props.header.subTitle
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-btn
          @click="
            card_header_props.helper.page_helper =
              !card_header_props.helper.page_helper
          "
          icon
          dark
        >
          <v-icon>info</v-icon>
        </v-btn>

        <v-dialog v-model="card_header_props.helper.page_helper" width="500">
          <v-card>
            <v-card-title class="headline grey lighten-2" primary-title
              >{{ $t("_common.How_can_I_use_this_form") }}
            </v-card-title>

            <v-card-text>{{
              $t("_exam_management._info.content")
            }}</v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="card_header_props.helper.page_helper = false"
                >{{ $t("_common.OK") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <!-- HEADER TOOLBAR -->

      <!-- ************************************** -->
      <!-- ***************content**************** -->
      <!-- ************************************** -->

      <!--
          @click-date="openAddNewEvent"
      -->
      <v-row>
        <v-col>
          <div class="vx-card no-scroll-content">
            <calendar-view
              ref="calendar"
              :displayPeriodUom="calendarView"
              :show-date="showDate"
              :events="simpleCalendarEvents"
              enableDragDrop
              :eventTop="windowWidth <= 400 ? '2rem' : '3rem'"
              eventBorderHeight="0px"
              eventContentHeight="1.65rem"
              class="theme-default"
              :locale="this.$i18n.locale"
              @click-event="openEditEvent"
              @drop-on-date="eventDragged"
            >
              <div slot="header" class="mb-4">
                <div class="vx-row no-gutter">
                  <!-- Month Name Add new event button -->
                  <div class="vx-col sm:w-1/3 w-full flex justify-left">
                    <vs-button
                      icon-pack="feather"
                      icon="icon-plus"
                      @click="promptAddNewEvent(new Date())"
                      >{{ $t("_exam_management.Exam") }}
                    </vs-button>
                    <vs-button
                      icon-pack="feather"
                      icon="icon-plus"
                      class="ml-1"
                      @click="promptAddNewMeeting(new Date())"
                      >{{ $t("_exam_management.Meeting") }}
                    </vs-button>
                  </div>
                  <v-spacer></v-spacer>
                  <!-- Current Month -->
                  <div
                    class="
                      vx-col
                      sm:w-1/3
                      w-full
                      sm:my-0
                      my-3
                      flex
                      sm:justify-end
                      justify-center
                      order-last
                    "
                  >
                    <div class="flex items-center">
                      <feather-icon
                        :icon="$vs.rtl ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
                        @click="updateMonth(-1)"
                        svgClasses="w-5 h-5 m-1"
                        class="
                          cursor-pointer
                          bg-primary
                          text-white
                          rounded-full
                        "
                      />

                      <span
                        class="mx-3 text-xl font-medium whitespace-no-wrap"
                        >{{ showDate | month }}</span
                      >

                      <feather-icon
                        :icon="$vs.rtl ? 'ChevronLeftIcon' : 'ChevronRightIcon'"
                        @click="updateMonth(1)"
                        svgClasses="w-5 h-5 m-1"
                        class="
                          cursor-pointer
                          bg-primary
                          text-white
                          rounded-full
                        "
                      />
                    </div>
                  </div>

                  <div class="vx-col sm:w-1/3 w-full flex justify-center">
                    <template v-for="(view, index) in calendarViewTypes">
                      <vs-button
                        v-if="calendarView === view.val"
                        :key="String(view.val) + 'filled'"
                        type="filled"
                        class="p-3 md:px-8 md:py-3"
                        :class="{
                          'border-l-0 rounded-l-none': index,
                          'rounded-r-none':
                            calendarViewTypes.length !== index + 1,
                        }"
                        @click="calendarView = view.val"
                        >{{ view.label }}
                      </vs-button>
                      <vs-button
                        v-else
                        :key="String(view.val) + 'border'"
                        type="border"
                        class="p-3 md:px-8 md:py-3"
                        :class="{
                          'border-l-0 rounded-l-none': index,
                          'rounded-r-none':
                            calendarViewTypes.length !== index + 1,
                        }"
                        @click="calendarView = view.val"
                        >{{ view.label }}
                      </vs-button>
                    </template>
                  </div>
                </div>

                <div class="vx-row sm:flex hidden mt-4">
                  <div class="vx-col w-full flex">
                    <!-- Labels -->
                    <div class="flex flex-wrap sm:justify-start justify-center">
                      <div
                        v-for="(label, index) in calendarLabels"
                        :key="index"
                        class="flex items-center mr-4 mb-2"
                      >
                        <div
                          class="h-3 w-3 inline-block rounded-full mr-2"
                          :class="'bg-' + label.color"
                        ></div>
                        <span>{{ label.text }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </calendar-view>
          </div>
        </v-col>
      </v-row>

      <!-- ************************************** -->
      <!-- ***************content**************** -->
      <!-- ************************************** -->
    </v-card>

    <!-- ADD MEETING -->
    <vs-prompt
      class="calendar-event-dialog"
      :title="
        meetingObject.id === null
          ? this.$t('_exam_management._add_meeting.title')
          : this.$t('_exam_management._add_meeting.Update_Meeting')
      "
      :accept-text="
        meetingObject.id === null
          ? this.$t('_exam_management._add_meeting.title')
          : this.$t('_exam_management._add_meeting.Update_Meeting')
      "
      :cancel-text="$t('_common.Cancel')"
      @accept="addMeeting"
      @close="cancelAddingMeeting"
      @cancel="cancelAddingMeeting"
      :is-valid="validMeetingForm"
      :active.sync="activePromptAddNewMeeting"
    >
      <div class="calendar__label-container flex">
        <vs-chip
          v-if="labelLocal != 'disabled'"
          class="text-white"
          :class="'bg-' + labelColor(labelLocal)"
        >
          {{ labelLocal }}
        </vs-chip>
      </div>

      <v-row>
        <v-col cols="12">
          <vs-input
            name="event-name"
            v-validate="'required'"
            class="w-full"
            :label-placeholder="
              this.$t('_exam_management._add_meeting.Event_Title')
            "
            v-model="meetingObject.title"
          ></vs-input>
        </v-col>

        <v-col cols="6">
          <date-time-picker
            :label="$t('_exam_management.Start_Date_And_Time')"
            v-model="meetingObject.startDate"
            :datePickerProps="{ min: new Date().toISOString().substr(0, 10) }"
          ></date-time-picker>
        </v-col>

        <v-col cols="6">
          <v-menu
            ref="menu"
            v-model="timePickerDialog"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="meetingObject.duration"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
            content-class="timePickerZindex"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="meetingObject.duration"
                :label="$t('_exam_management.Duration')"
                prepend-icon="access_time"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="timePickerDialog"
              v-model="meetingObject.duration"
              full-width
              format="24hr"
              @click:minute="$refs.menu.save(meetingObject.duration)"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="timePickerDialog = false">{{
                $t("_common.Cancel")
              }}</v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menu.save(meetingObject.duration)"
                >{{ $t("_common.OK") }}</v-btn
              >
            </v-time-picker>
          </v-menu>
        </v-col>

        <v-col cols="9">
          <vs-button
            type="flat"
            v-if="meetingObject.url !== ''"
            class="col-12"
            icon-pack="feather"
            icon="icon-link"
            @click="goToMeeting(meetingObject.url)"
            >{{ $t("_exam_management._add_meeting.Go_to_meeting") }}
          </vs-button>
        </v-col>
        <v-col cols="3">
          <vs-button
            v-if="meetingObject.url !== ''"
            class="col-12 vs-button-danger"
            icon-pack="feather"
            icon="icon-x-circle"
            @click="deleteMeeting(meetingObject)"
            >{{ $t("_common.Delete") }}
          </vs-button>
        </v-col>
      </v-row>
    </vs-prompt>

    <!-- ADD EVENT -->
    <vs-prompt
      class="calendar-event-dialog"
      title="Add Event"
      accept-text="Add Event"
      @accept="addEvent"
      :is-valid="validForm"
      :active.sync="activePromptAddEvent"
    >
      <div class="calendar__label-container flex">
        <vs-chip
          v-if="labelLocal != 'disabled'"
          class="text-white"
          :class="'bg-' + labelColor(labelLocal)"
        >
          {{ labelLocal }}
        </vs-chip>
      </div>

      <vs-input
        name="event-name"
        v-validate="'required'"
        class="w-full"
        label-placeholder="Event Title"
        v-model="eventObject.title"
      ></vs-input>
      <div class="my-4">
        <small class="date-label">{{
          $t("_exam_management.Start_Date")
        }}</small>
        <datepicker
          :language="$vs.rtl ? langHe : langEn"
          name="start-date"
          v-model="eventObject.startDate"
          :disabled="disabledFrom"
        ></datepicker>
      </div>
      <div class="my-4">
        <small class="date-label">{{ $t("_exam_management.End_Date") }}</small>
        <datepicker
          :language="$vs.rtl ? langHe : langEn"
          :disabledDates="disabledDatesTo"
          name="end-date"
          v-model="eventObject.endDate"
        ></datepicker>
      </div>
      <vs-checkbox v-model="eventObject.status">{{
        $t("_exam_management.Active")
      }}</vs-checkbox>
    </vs-prompt>

    <!-- EDIT EVENT -->
    <vs-prompt
      class="calendar-event-dialog"
      :title="$t('_exam_management.Edit_Event')"
      :cancel-text="$t('_common.Cancel')"
      :accept-text="$t('_common.Save')"
      button-cancel="border"
      @cancel="removeEvent"
      @accept="editEvent"
      :is-valid="validForm"
      :active.sync="activePromptEditEvent"
      :buttons-hidden="disableForm"
    >
      <div class="calendar__label-container flex">
        <vs-chip class="text-white" :class="'bg-' + labelColor(labelLocal)">
          {{ labelLocal }}
        </vs-chip>
      </div>

      <v-row>
        <v-col cols="12">
          <vs-input
            name="event-name"
            v-validate="'required'"
            class="w-full"
            :label-placeholder="$t('_exam_management.Event_Title')"
            v-model="eventObject.title"
            :disabled="disableForm"
          ></vs-input>
        </v-col>
        <v-col cols="6">
          <small class="date-label">{{
            this.$t("_exam_management.Start_Date")
          }}</small>
          <datepicker
            v-validate="'required'"
            :language="$vs.rtl ? langHe : langEn"
            name="start-date"
            v-model="eventObject.startDate"
            @input="activationDateMenu = false"
            :disabled="disableForm"
            :disabled-dates="{ to: new Date() }"
          ></datepicker>
        </v-col>
        <v-col cols="6">
          <small class="date-label">{{
            this.$t("_exam_management.End_Date")
          }}</small>
          <datepicker
            :language="$vs.rtl ? langHe : langEn"
            :disabledDates="disabledDatesTo"
            name="end-date"
            v-model="eventObject.endDate"
            :disabled="disableForm"
          ></datepicker>
        </v-col>
        <v-col cols="6">
          <v-menu
            ref="startTimeMenu"
            v-model="startTimePicker"
            :close-on-content-click="false"
            :return-value.sync="eventObject.startTime"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
            z-index="60000"
            style="margin-top: 10px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="eventObject.startTime"
                :label="$t('_create_exam.StartTime')"
                readonly
                dense
                v-bind="attrs"
                v-on="on"
                prepend-icon="access_time"
                @blur="startTimePicker = false"
                :disabled="disableForm"
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="startTimePicker"
              v-model="eventObject.startTime"
              full-width
              @click:minute="$refs.startTimeMenu.save(eventObject.startTime)"
              :min="minStartTime"
              format="24hr"
            ></v-time-picker>
          </v-menu>
        </v-col>
        <v-col cols="6">
          <v-menu
            ref="endTimeMenu"
            v-model="endTimePicker"
            :close-on-content-click="false"
            :return-value.sync="eventObject.endTime"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
            z-index="60000"
            style="margin-top: 10px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="eventObject.endTime"
                :label="$t('_create_exam.EndTime')"
                readonly
                dense
                v-bind="attrs"
                v-on="on"
                prepend-icon="access_time"
                @blur="endTimePicker = false"
                :disabled="disableForm"
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="endTimePicker"
              v-model="eventObject.endTime"
              full-width
              @click:minute="$refs.endTimeMenu.save(eventObject.endTime)"
              :min="minStartTime"
              format="24hr"
            ></v-time-picker>
          </v-menu>
        </v-col>
        <v-col cols="12">
          <vs-checkbox v-model="eventObject.status" :disabled="disableForm"
            >{{ this.$t("_exam_management.Active") }}
          </vs-checkbox>
        </v-col>
        <!-- <v-col cols="12">
          <vs-button
            class="col-12"
            icon-pack="feather"
            icon="icon-mail"
            @click="sentMail()"
          >Sent mail to participants.</vs-button>
        </v-col>-->
      </v-row>
    </vs-prompt>
  </div>
</template>

<script>
import { CalendarView, CalendarViewHeader } from "vue-simple-calendar";
import moduleCalendar from "@/store/calendar/moduleCalendar.js";
import DateTimePicker from "../../../../goc/vsComponents/DateTimePicker/DatetimePicker.vue";

require("vue-simple-calendar/static/css/default.css");

import Datepicker from "vuejs-datepicker";
import { en, he } from "vuejs-datepicker/src/locale";
import AssignTopic from "@/views/neo/AssignTopic";

export default {
  components: {
    CalendarView,
    CalendarViewHeader,
    Datepicker,
    DateTimePicker,
  },
  data() {
    return {
      disableForm: false,
      isSendMail: false,
      calendarLabels: [],
      simpleCalendarEvents: [],
      showDate: new Date(),
      disabledFrom: false,
      eventObject: this.getNewEventObject(),

      labelLocal: null,
      langHe: he,
      langEn: en,

      calendarView: "month",

      activePromptAddEvent: false,
      activePromptEditEvent: false,
      activePromptAddNewMeeting: false,
      timePickerDialog: false,
      meetingObject: {
        title: "",
        startDate: new Date(),
        endDate: new Date(),
        duration: "00:09:00",
        status: true,
      },

      calendarViewTypes: [
        {
          label: this.$t("_exam_management.Month"),
          val: "month",
        },
        {
          label: this.$t("_exam_management.Week"),
          val: "week",
        },
      ],
      card_header_props: {
        header: {
          headLine: this.$t("_exam_management.Exam_Management"),
          subTitle: this.$t("_exam_management.Subtitle"),
          icon: "mdi-pulse",
        },
        helper: {
          absolute: true,
          opacity: 0.8,
          overlay: false,
          page_helper: false,
        },
      },
      startTimePicker: false,
      endTimePicker: false,
      activationDate: new Date().toISOString().substr(0, 10),
      activationMinDate: new Date().toISOString().substr(0, 10),
      expirationDate: null,
    };
  },
  computed: {
    /**

       {
      id: 1,
      title: "My Event",
      startDate: "2020-04-05T20:17:12.177Z",
      endDate: "2020-04-06T20:17:12.177Z",
      url: "",
      classes: "event-success",
      label: "business"
    }
       */
    // simpleCalendarEvents() {

    //   // return this.$store.state.calendar.events;
    // },
    validMeetingForm() {
      let result =
        this.meetingObject.title != "" && this.meetingObject.startDate != "";
      return result;
    },
    validForm() {
      return (
        this.eventObject.title != "" &&
        this.eventObject.startDate != "" &&
        this.eventObject.endDate != "" &&
        Date.parse(this.eventObject.endDate) -
          Date.parse(this.eventObject.startDate) >=
          0
      );
    },
    disabledDatesTo() {
      return { to: new Date(this.startDate) };
    },
    disabledDatesFrom() {
      return { from: new Date(this.endDate) };
    },
    labelColor() {
      return (label) => {
        if (label == "upcoming") return "success";
        if (label == "ongoing") return "warning";
        if (label == "expired") return "danger";
        if (label == "disabled") return "primary";
        if (label == "meeting") return "dark";
      };
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    minStartFirstTime() {
      if (new Date(this.activationDate).getDate() === new Date().getDate())
        return this.$moment(new Date()).format("HH:mm");
    },

    minStartTime() {
      if (
        new Date(this.activationDate).getDate() ===
        new Date(this.expirationDate).getDate()
      ) {
        const start = this.$moment(this.startDate);
        const end = this.$moment(this.endDate);

        return end > start ? null : this.startTime;
      }
    },
    startDateTime() {
      const startDate = this.$moment(
        `${this.eventObject.startDate} ${this.startTime}`,
        "YYYY-MM-DD HH:mm"
      );
      return startDate.format();
    },
    endDateTime() {
      const endDate = this.$moment(
        `${this.eventObject.endDate} ${this.endTime}`,
        "YYYY-MM-DD HH:mm"
      );
      return endDate.format();
    },
  },

  mounted() {
    this.reloadCalendar();

    if (
      this.$route.query.addmeeting === "1" ||
      this.$route.query.addmeeting === "2"
    ) {
      this.promptAddNewMeeting();
    }

    this.calendarLabels = [
      {
        text: this.$t("_exam_management.Upcoming"),
        value: "upcoming",
        color: "success",
      },
      {
        text: this.$t("_exam_management.Ongoing"),
        value: "ongoing",
        color: "warning",
      },
      {
        text: this.$t("_exam_management.Expired"),
        value: "expired",
        color: "danger",
      },
      {
        text: this.$t("_exam_management.Disabled"),
        value: "disabled",
        color: "primary",
      },
      {
        text: this.$t("_exam_management.Meeting"),
        value: "meeting",
        color: "dark",
      },
    ];

    this.$goc.setModule("ExamManagement", this);
  },
  watch: {
    "eventObject.startDate"() {
      this.startTime = null;
    },
    "eventObject.endDate"() {
      this.endTime = null;
    },
  },

  methods: {
    goToMeeting(url) {
      window.open(url, "_blank");
    },
    reloadCalendar() {
      // ****************************************************************************************** //
      this.simpleCalendarEvents = [];
      this.getQuestionSheetList((sheets) => {
        this.simpleCalendarEvents = [...this.simpleCalendarEvents, ...sheets];
        this.getMeeting(undefined, (meetingList) => {
          this.simpleCalendarEvents = [
            ...this.simpleCalendarEvents,
            ...meetingList,
          ];
        });
      });
    },
    checkStuation(event) {
      if (
        event.originalObj.StartedUserCount == 0 &&
        event.originalObj.FinishedUserCount == 0
      ) {
        this.disableForm = false;
        return false;
      } else {
        this.disableForm = false;
        return false;
      }
    },

    getNewEventObject() {
      return {
        id: null,
        title: "",
        startDate: new Date(),
        endDate: new Date(),
        label: "disabled",
        assignedDate: new Date(),
        activationDate: new Date(),
        expirationDate: new Date(),
        startTime: null,
        endTime: null,
        assignedUserCount: 0,
        startedUserCount: 0,
        finishedUserCount: 0,
        classes: "event-disabled",
        status: true,
        originalObj: null,
      };
    },
    convertObject(arrList) {
      let me = this;
      let calData = arrList.map((e) => {
        let label = "disabled";
        let status = true;
        let activDateDiff = this.$goc
          .moment(new Date(e.ActivationDate))
          .diff(new Date());
        let expDateDiff = this.$goc
          .moment(new Date(this.$goc.moment(e.ExpirationDate).add(1, "days")))
          .diff(new Date());
        if (activDateDiff < 0 && expDateDiff > 0) {
          label = "ongoing";
        } else if (activDateDiff > 0) {
          label = "upcoming";
        } else if (expDateDiff < 0) {
          label = "expired";
        }
        if (e.Status == me.$enums.ASSET_STATUS.PASSIVE) {
          label = "disabled";
          status = false;
        }

        let eventObject = this.getNewEventObject();
        eventObject.id = e.Id;
        eventObject.title = e.Title;
        eventObject.startDate = new Date(
          goc.moment(e.ActivationDate).valueOf()
        );
        eventObject.endDate = new Date(goc.moment(e.ExpirationDate).valueOf());
        eventObject.assignedDate = e.AssignedDate;
        eventObject.activationDate = e.ActivationDate;
        eventObject.expirationDate = e.ExpirationDate;
        eventObject.assignedUserCount = e.AssignedUserCount;
        eventObject.startedUserCount = e.StartedUserCount;
        eventObject.finishedUserCount = e.FinishedUserCount;
        eventObject.status = status;
        eventObject.startTime = goc.moment(e.ActivationDate).format("HH:mm");
        eventObject.endTime = goc.moment(e.ExpirationDate).format("HH:mm");
        eventObject.label = label;
        eventObject.labelLocal = label;
        eventObject.classes = "event-" + this.labelColor(label);
        eventObject.originalObj = e;
        return eventObject;
      });
      return calData;
    },
    getQuestionSheetList(callback) {
      let url = this.$enums.API.QuestionSheet;
      return this.getQuestionSheet(url, callback);
    },
    getQuestionSheetById(id, callback) {
      let url = this.$enums.API.QuestionSheet + "/" + id;
      return this.getQuestionSheet(url, callback);
    },
    getQuestionSheet(url, callback) {
      this.$goc.request.get(url, (res) => {
        if (res.IsSuccess) {
          let calData = this.convertObject(res.Result.Sheets);
          if (callback && typeof callback === "function") {
            callback(calData);
          }
        }
      });
    },
    addEvent() {
      let eventObject = this.getNewEventObject();
      this.$store.dispatch("calendar/addEvent", eventObject);
    },

    calculateDuration(seconds) {
      let hours = this.$moment.duration(parseInt(seconds), "seconds").hours();
      let minutes = this.$moment
        .duration(parseInt(seconds), "seconds")
        .minutes();
      let calculatedDuration = hours < 10 ? "0" + hours : hours;
      calculatedDuration =
        calculatedDuration + ":" + (minutes < 10 ? "0" + minutes : minutes);
      return calculatedDuration;
    },
    getMeeting(id, callback) {
      let me = this;

      if (id) {
        this.$goc.request.get(
          this.$enums.API.Meeting + "/" + id,
          (meetings) => {
            if (meetings.IsSuccess) {
              let calData = meetings.Result.Meetings.map((e) => {
                let calculatedEndDate = this.$moment(e.StartDate).add(
                  e.Duration,
                  "seconds"
                );
                let endDate = new Date(
                  this.$moment(calculatedEndDate).format("YYYY-MM-DD")
                ).getTime();

                return {
                  id: e.Id,
                  title:
                    goc.moment(e.StartDate).format("HH:mm") + " - " + e.Title,
                  orjinalTitle: e.Title,
                  startDate: e.StartDate,
                  endDate: endDate,
                  duration: me.calculateDuration(e.Duration),
                  label: "meeting",
                  classes: "event-" + this.labelColor("meeting"),
                  url: this.$enums.URL.meeting + e.Url,
                };
              });

              if (callback && typeof callback == "function") {
                callback(calData);
              }
            }
          }
        );
      } else {
        const from =
          new Date(
            this.$moment(this.showDate)
              .startOf("month")
              .format("YYYY-MM-DD HH:mm")
          ).getTime() / 1000;
        const to =
          new Date(
            this.$moment(this.showDate)
              .endOf("month")
              .format("YYYY-MM-DD HH:mm")
          ).getTime() / 1000;

        let body = {
          From: {
            Seconds: from,
            Nanos: 0,
          },
          To: {
            Seconds: to,
            Nanos: 0,
          },
        };

        this.$goc.request.post(
          this.$enums.API.ListMeeting,
          body,
          (meetings) => {
            if (meetings.IsSuccess) {
              let calData = meetings.Result.Meetings.map((e) => {
                let calculatedEndDate = this.$moment(e.StartDate).add(
                  e.Duration,
                  "seconds"
                );
                let endDate = new Date(
                  this.$moment(calculatedEndDate).format("YYYY-MM-DD")
                ).getTime();

                return {
                  id: e.Id,
                  title:
                    goc.moment(e.StartDate).format("HH:mm") + " - " + e.Title,
                  orjinalTitle: e.Title,
                  startDate: e.StartDate,
                  endDate: endDate,
                  duration: me.calculateDuration(e.Duration),
                  label: "meeting",
                  classes: "event-" + this.labelColor("meeting"),
                  url: this.$enums.URL.meeting + e.Url,
                };
              });

              if (callback && typeof callback == "function") {
                callback(calData);
              }
            }
          }
        );
      }
    },
    deleteMeeting(meeting) {
      this.$goc.notify.confirm(
        {
          title: this.$t("_common.Delete"),
          message: this.$t(
            "_exam_management._add_meeting.DoYouWantDeleteMeeting"
          ),
        },
        (deleteConfirm) => {
          var confirm;
          var lang = localStorage.getItem("lmsSelectedLang") == "tr" ? 1 : 2;
          if (deleteConfirm) {
            this.$goc.notify.confirm(
              {
                title: this.$t("_common.SentMail"),
                message: this.$t("_exam_management.DoYouWantSendEmail"),
              },
              (isSentMail) => {
                this.$goc.notify.success({
                  title: this.$t("_notification.NotificationMail"),
                  message: this.$t(
                    "_notification.NotificationSentSuccessfully"
                  ),
                });
                this.$goc.request.delete(
                  this.$enums.API.Meeting +
                    "/" +
                    meeting.id +
                    "/" +
                    isSentMail +
                    "/" +
                    lang,
                  () => {
                    this.$goc.notify.success({
                      title: this.$t("_common.Success"),
                      message: this.$t(
                        "_exam_management._add_meeting.Meeting_Deleted"
                      ),
                    });
                    this.reloadCalendar();
                  }
                );
              }
            );
          }
        }
      );
    },

    addMeeting() {
      var durationSeconds = this.$moment
        .duration(this.meetingObject.duration)
        .asSeconds();

      let startDateSeconds =
        new Date(
          this.$moment(this.meetingObject.startDate).format("YYYY-MM-DD HH:mm")
        ).getTime() / 1000;

      /*
        let calculatedEndDate = this.$moment(this.meetingObject.startDate).add(durationSeconds, 'seconds');

        let endDate =
          new Date(
            this.$moment(calculatedEndDate).format("YYYY-MM-DD")
          ).getTime() / 1000;
  */

      this.meetingObject.duration = { Seconds: durationSeconds, Nanos: 0 };
      this.meetingObject.startDate = { Seconds: startDateSeconds, Nanos: 0 };

      if (this.meetingObject.id === null) {
        this.$goc.request.post(
          this.$enums.API.Meeting,
          this.meetingObject,
          (addMeetingResult) => {
            if (addMeetingResult.IsSuccess) {
              this.$goc.notify.success({
                title: this.$t("_common.Success"),
                message: this.$t(
                  "_exam_management._add_meeting.Meeting_Created"
                ),
              });
              this.reloadCalendar();
              if (this.$route.query.addmeeting === "1") {
                this.$goc.page.load("/assign/topic/Meetings");
              } else if (this.$route.query.addmeeting === "2") {
                this.$goc.page.load("/assign/class/Meetings");
              }
            }
          }
        );
      } else {
        this.meetingObject.Language =
          localStorage.getItem("lmsSelectedLang") == "tr" ? 1 : 2;
        this.meetingObject.isSendMail = false;
        var confirm = this.$goc.notify.confirm(
          {
            title: this.$t("_common.SentMail"),
            message: this.$t("_exam_management.DoYouWantSendEmail"),
          },
          (isSentMail) => {
            if (isSentMail) {
              this.meetingObject.isSendMail = true;
              this.$goc.notify.success({
                title: this.$t("_notification.NotificationMail"),
                message: this.$t("_notification.NotificationSentSuccessfully"),
              });
            }
            this.$goc.request.put(
              this.$enums.API.Meeting,
              this.meetingObject,
              (addMeetingResult) => {
                if (addMeetingResult.IsSuccess) {
                  this.$goc.notify.success({
                    title: this.$t("_common.Success"),
                    message: this.$t(
                      "_exam_management._add_meeting.Meeting_Updated"
                    ),
                  });
                }
                this.reloadCalendar();
              }
            );
          }
        );
      }
    },

    cancelAddingMeeting() {
      setTimeout(() => {
        if (this.$route.query.addmeeting === "1") {
          this.$goc.page.load("/assign/topic/Meetings");
        } else if (this.$route.query.addmeeting === "2") {
          this.$goc.page.load("/assign/class/Meetings");
        }
      });
    },
    updateMonth(val) {
      this.showDate = this.$refs.calendar.getIncrementedPeriod(val);
      this.reloadCalendar();
    },
    clearFields() {
      this.eventObject.title = this.endDate = "";
      this.eventObject.id = 0;
      this.labelLocal = "disabled";
    },
    promptAddNewEvent(date) {
      this.$goc.page.load("/add/exam/");
    },
    promptAddNewMeeting() {
      this.activePromptAddNewMeeting = true;
      (this.meetingObject.title = ""),
        (this.meetingObject.id = null),
        (this.meetingObject.startDate = new Date());
      this.meetingObject.endDate = new Date();
      this.meetingObject.duration = "00:00";
      this.meetingObject.url = "";
      this.meetingObject.active = true;
    },
    addNewEventDialog(date) {
      this.clearFields();
      this.eventObject.startDate = date;
      this.eventObject.endDate = date;
      this.activePromptAddEvent = true;
    },
    openAddNewEvent(date) {
      this.disabledFrom = true;
      this.addNewEventDialog(date);
    },
    openEditEvent(event) {
      let me = this;

      if (!event.originalEvent.url) {
        this.checkStuation(event.originalEvent);
        if (
          new Date().getDate() > event.endDate.getDate() &&
          new Date().getMonth() == event.endDate.getMonth()
        ) {
          this.disableForm = true;
        }
        this.getQuestionSheetById(event.id, (res) => {
          let e = res[0];
          me.eventObject = e;
          me.labelLocal = e.label;
          me.activePromptEditEvent = true;
        });
      } else {
        this.getMeeting(event.id, (meeting) => {
          me.meetingObject.id = meeting[0].id;
          me.meetingObject.title = meeting[0].orjinalTitle;
          me.meetingObject.startDate = new Date(
            me.$moment(meeting[0].startDate).format("YYYY.MM.DD HH:mm")
          );
          me.meetingObject.duration = meeting[0].duration;
          me.meetingObject.url = meeting[0].url;
          me.labelLocal = "meeting";
          me.activePromptAddNewMeeting = true;
        });

        // window.open(event.originalEvent.url, '_blank');
      }
    },
    editEvent() {
      let eventObject = this.eventObject;

      if (!this.checkStuation(eventObject)) {
        eventObject.originalObj.Status = eventObject.status
          ? this.$enums.ASSET_STATUS.ACTIVE
          : this.$enums.ASSET_STATUS.PASSIVE;

        const startDate = this.$moment(
          `${this.$moment(this.eventObject.startDate).format("YYYY-MM-DD")} ${
            this.eventObject.startTime
          }`,
          "YYYY-MM-DD HH:mm"
        );
        const endDate = this.$moment(
          `${this.$moment(this.eventObject.endDate).format("YYYY-MM-DD")} ${
            this.eventObject.endTime
          }`,
          "YYYY-MM-DD HH:mm"
        );

        let activationDateTime = new Date(startDate);
        let expirationDateTime = new Date(endDate);
        let activationDateSeconds = activationDateTime.getTime() / 1000;
        let expirationDateSeconds = expirationDateTime.getTime() / 1000;

        eventObject.originalObj.ActivationDate = {
          Seconds: activationDateSeconds,
          Nanos: 0,
        };
        eventObject.originalObj.ExpirationDate = {
          Seconds: expirationDateSeconds,
          Nanos: 0,
        };
        eventObject.originalObj.Time = {
          Seconds: parseInt(eventObject.originalObj.Time),
          Nanos: 0,
        };

        eventObject.originalObj.Title = eventObject.title;
        eventObject.originalObj.Language =
          localStorage.getItem("lmsSelectedLang") == "tr" ? 1 : 2;
        eventObject.originalObj.isSendMail = false;
        this.$goc.notify.confirm(
          {
            title: this.$t("_common.SentMail"),
            message: this.$t("_exam_management.DoYouWantSendEmail"),
          },
          (isSentMail) => {
            if (isSentMail) {
              eventObject.originalObj.isSendMail = true;
              this.$goc.notify.success({
                title: this.$t("_notification.NotificationMail"),
                message: this.$t("_notification.NotificationSentSuccessfully"),
              });
            }
            this.$goc.request.put(
              this.$enums.API.QuestionSheet,
              eventObject.originalObj,
              (response) => {
                if (response.IsSuccess) {
                  this.$goc.notify.success({
                    title: this.$t("_common.Success"),
                    message: this.$t(
                      "_exam_management.ExamNotification.SheetUpdated"
                    ),
                  });
                }
                this.reloadCalendar();
              }
            );
          }
        );
      } else {
        this.$goc.notify.warning({
          title: this.$t("_common.Warning"),
          message: this.$t(
            "_exam_management.ExamNotification.YouCantUpdateExam"
          ),
        });
      }
    },
    removeEvent() {
      this.$store.dispatch("calendar/removeEvent", this.eventObject.id);
    },
    eventDragged(event, date) {
      if (
        new Date().getDate() > event.endDate.getDate() &&
        new Date().getMonth() == event.endDate.getMonth()
      )
        return;

      if (
        event.originalEvent.originalObj.StartedUserCount == 0 &&
        event.originalEvent.originalObj.FinishedUserCount == 0
      ) {
        this.$goc.console.log(event, date);
        let dateDiffInDays = this.$goc
          .moment(event.endDate)
          .diff(event.startDate, "days");
        let newEndDate = goc.moment(date).add(dateDiffInDays, "days");

        let activationDateSeconds =
          new Date(this.$moment(date).format("YYYY-MM-DD")).getTime() / 1000;
        let expirationDateSeconds =
          new Date(this.$moment(newEndDate).format("YYYY-MM-DD")).getTime() /
          1000;

        event.originalEvent.originalObj.ActivationDate = {
          Seconds: activationDateSeconds,
          Nanos: 0,
        };
        event.originalEvent.originalObj.ExpirationDate = {
          Seconds: expirationDateSeconds,
          Nanos: 0,
        };
        let time = event.originalEvent.originalObj.Time;
        event.originalEvent.originalObj.Time = {
          Seconds: parseInt(time),
          Nanos: 0,
        };
        this.$goc.request.put(
          this.$enums.API.QuestionSheet,
          event.originalEvent.originalObj,
          (response) => {
            this.$goc.notify.success({
              title: this.$t("_common.Success"),
              message: this.$t(
                "_exam_management.ExamNotification.SheetUpdated"
              ),
            });
            this.getQuestionSheetList((sheets) => {
              this.reloadCalendar();
              this.clearForm();
            });
          }
        );
      } else {
        this.$goc.notify.warning({
          title: this.$t("_common.Warning"),
          message: this.$t(
            "_exam_management.ExamNotification.YouCantUpdateExam"
          ),
        });
      }
    },
  },
  created() {
    this.$store.registerModule("calendar", moduleCalendar);
    this.$store.dispatch("calendar/fetchEvents");
    this.$store.dispatch("calendar/fetchEventLabels");
  },
  beforeDestroy() {
    this.$store.unregisterModule("calendar");
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/simple-calendar.scss";
</style>

<style scoped>
.flex,
.child-flex > * {
  flex: 0 0 auto !important;
}
</style>

<style>
.timePickerZindex {
  z-index: 52006 !important;
}

.theme-default .cv-event.event-dark {
  background-color: rgb(136, 0, 247) !important;
}

.bg-dark {
  background-color: rgb(136, 0, 247) !important;
}
</style>
